// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-companies-js": () => import("./../../../src/pages/companies.js" /* webpackChunkName: "component---src-pages-companies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-in-chennai-ui-ux-agency-commutatus-js": () => import("./../../../src/pages/in/chennai/ui-ux-agency/commutatus.js" /* webpackChunkName: "component---src-pages-in-chennai-ui-ux-agency-commutatus-js" */),
  "component---src-pages-in-chennai-ui-ux-agency-index-js": () => import("./../../../src/pages/in/chennai/ui-ux-agency/index.js" /* webpackChunkName: "component---src-pages-in-chennai-ui-ux-agency-index-js" */),
  "component---src-pages-in-chennai-ui-ux-agency-indusnettechnologiespvtltd-js": () => import("./../../../src/pages/in/chennai/ui-ux-agency/indusnettechnologiespvtltd.js" /* webpackChunkName: "component---src-pages-in-chennai-ui-ux-agency-indusnettechnologiespvtltd-js" */),
  "component---src-pages-in-chennai-ui-ux-agency-ionixxtechnologies-js": () => import("./../../../src/pages/in/chennai/ui-ux-agency/ionixxtechnologies.js" /* webpackChunkName: "component---src-pages-in-chennai-ui-ux-agency-ionixxtechnologies-js" */),
  "component---src-pages-in-chennai-ui-ux-agency-lollypop-js": () => import("./../../../src/pages/in/chennai/ui-ux-agency/lollypop.js" /* webpackChunkName: "component---src-pages-in-chennai-ui-ux-agency-lollypop-js" */),
  "component---src-pages-in-chennai-ui-ux-agency-originux-js": () => import("./../../../src/pages/in/chennai/ui-ux-agency/originux.js" /* webpackChunkName: "component---src-pages-in-chennai-ui-ux-agency-originux-js" */),
  "component---src-pages-in-chennai-ui-ux-agency-rillusion-js": () => import("./../../../src/pages/in/chennai/ui-ux-agency/rillusion.js" /* webpackChunkName: "component---src-pages-in-chennai-ui-ux-agency-rillusion-js" */),
  "component---src-pages-in-chennai-ui-ux-agency-skcript-js": () => import("./../../../src/pages/in/chennai/ui-ux-agency/skcript.js" /* webpackChunkName: "component---src-pages-in-chennai-ui-ux-agency-skcript-js" */),
  "component---src-pages-in-chennai-ui-ux-agency-vamosdigital-js": () => import("./../../../src/pages/in/chennai/ui-ux-agency/vamosdigital.js" /* webpackChunkName: "component---src-pages-in-chennai-ui-ux-agency-vamosdigital-js" */),
  "component---src-pages-in-chennai-ui-ux-agency-w-2-ssolutions-js": () => import("./../../../src/pages/in/chennai/ui-ux-agency/w2ssolutions.js" /* webpackChunkName: "component---src-pages-in-chennai-ui-ux-agency-w-2-ssolutions-js" */),
  "component---src-pages-in-chennai-ui-ux-agency-zencodetechnologies-js": () => import("./../../../src/pages/in/chennai/ui-ux-agency/zencodetechnologies.js" /* webpackChunkName: "component---src-pages-in-chennai-ui-ux-agency-zencodetechnologies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-js": () => import("./../../../src/pages/latest.js" /* webpackChunkName: "component---src-pages-latest-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-company-js": () => import("./../../../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */)
}

